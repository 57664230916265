import Layout from "../components/layout";
import Helmet from "react-helmet";
import React, {Component} from "react"
import { Link } from "gatsby"
export default class FourZeroFour extends Component {
    constructor(props) {
        super(props);
        this.state={
            url: ''
        }
    }

    componentDidMount() {
        this.setState({
            url: document.location.href
        });
    }
    render() {
        return (
            <Layout>
                <Helmet>
                    <title>
                        404 - Page not found </title>
                    <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
                    <meta name="viewport"
                          content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
                    <link rel="canonical" href={this.state.url}/>
                    <meta name="description"
                          content="The page you're looking for could not be found."/>
                    <meta property="og:site_name" content="Cueblocks"/>
                    <meta property="fb:app_id" content="289086684439915"/>
                    <meta property="og:url" content={this.state.url}/>
                    <meta property="og:title"
                          content="The page you're looking for could not be found."/>
                    <meta property="og:description"
                          content="The page you're looking for could not be found."/>
                    <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:description"
                          content="The page you're looking for could not be found."/>
                    <meta name="twitter:title"
                          content="The page you're looking for could not be found."/>
                    <meta name="twitter:site" content="@cueblocks"/>
                    <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
                </Helmet>
                <section className="ser_sec_row error-page sitemap-page thankyou-page">
                    <div className="container">
                        <div className="main-heading">
                            <h6>IT'S A 404 ERROR</h6>
                            <h2 className="heading_main">The page you're looking for could not be found.</h2>

                            <p>Now you might want to: </p>
                            <ul className="outerul">
                                <li><Link to="/" >Go back to the homepage</Link> </li>
                                <li><Link to="/services" >Find out about our services</Link> </li>
                                <li><Link to="/about" >Get to know us a little better</Link> </li>
                                <li><Link to="/career" >Look at job opportunities at CueForGood</Link> </li>
                                <li><a href="https://www.cueforgood.com/blog/" >Go to our Blog</a> </li>
                                <li><a href="https://store.cueblocks.com/" >Check out our Magento Extensions Store</a> </li>
                                <li><Link to="/contact" >Get in touch with us</Link> </li>
                             </ul>
                        </div>

                    </div>
                </section>
            </Layout>
        )
    }
}